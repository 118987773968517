<template>
    <header>
        <router-link id="logo" to="/">
            <img src="@/assets/logo.png" alt="Liga Rozwoju" />
        </router-link>

    <nav :class="{'menu-open':(menuOpened)}">
        <router-link v-for="route in routes" :key="route.path"
            class="nav-link"
            :class="{'nav-active':(route.path == activeRoute.path)}"
            :to="route.path">
            {{route.name}}
        </router-link>
    </nav>
    <a id="nav-burger" @click="toggleMenu" class="icon">
        <i class="line"></i>
        <i class="line"></i>
        <i class="line"></i>
    </a>
    </header>

</template>

<script>
export default {
    data(){
        return {
            menuOpened:false
        }
    },
    methods:{
        toggleMenu(){
            this.menuOpened = !this.menuOpened
        }
    },
    computed:{
        routes(){
            return this.$router.getRoutes()//.filter((route)=>route.path !="/")
        },
        activeRoute(){
            return this.$route
        }
    }
    
}
</script>

