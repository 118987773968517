<template>
  <footer>
    <div class="partners">
      <div><img src="@/assets/uek.png" /></div>
      <div><img src="@/assets/kzzp.png" /></div>
      <div><img src="@/assets/knro_b.png" /></div>

      <div><img src="@/assets/liga.png" /></div>
      <div><img src="@/assets/seed.png" /></div>
    </div>
    <p>Strona stworzona z 🤍 przez zespół Ligi Rozwoju 2021/22</p>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="sass">

footer
  display: grid
  background: white
  color: black
  text-align: center

  grid-auto-flow: row




  div.partners
    padding: 50px
    // grid-template-rows: repeat(auto-fit, minmax(100px, 1fr))
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))

    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    div
      align-self: center
      width: 150px
      padding: 20px
      text-align: center
      img
        object-fit: contain
        max-width: 90%
        height: 78px
  p
    padding-bottom: 16px

</style>