<template>
  <main>
    <div>
      <h2>
        Nowy wymiar nauki
      </h2>
      <h1 class="accent-color">
        Ucz się, kiedy chcesz!
      </h1>
    </div>
    <div>
      <h4 class="italic">Wspinaj się na wyżyny swoich możliwości,</h4>
      <h4 class="italic">poszerzaj horyzonty, a co najważniejsze:</h4>
      <h4 class="italic">Dobrze się z nami baw, zapraszamy!</h4>
      <h5>Zespół Ligi</h5>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLScs0It33ECH1LlZ1FvW6Vmm1gE0p4s4Ms6qX0FHubLP7FN9DA/viewform?usp=sf_link"><button class="accent-background light-color main-button"> Dołącz do Nas jako Prowadzący </button></a>
    </div> 
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {

  }
}
</script>
<style lang="sass" scoped>
main
  justify-content: space-between

</style>