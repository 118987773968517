<template>
    <section class="spacer"> </section>

</template>

<script>
export default {
    name:"spacer"
}
</script>

<style>

</style>