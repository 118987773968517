<template>
  <Header class="dark" />
  <router-view class="light"/>
  <Spacer class="dark" />
  <Footer class="light" />
</template>
<script>
import Header from '@/components/Header.vue'
import Spacer from '@/components/Spacer.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'Liga Rozwoju',
  components: {
    Header,
    Spacer,
    Footer
  }
}
</script>



<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap')
$color-dark: #343434
$color-light: #fff
$color-accent: #4d74ff

$spacer-size: min(128px, max(64px, 10vw))
=keyframes($name)
  @-webkit-keyframes #{$name}
    @content
  @-moz-keyframes #{$name}
    @content
  @-ms-keyframes #{$name}
    @content
  @keyframes #{$name}
    @content

@keyframes menu_anim
  0%
    max-height: 0
  100%
    max-height: 100vh

.dark
  background: $color-dark
  color: $color-light

.light
  background: $color-light
  color: $color-dark

.dark-color
  color: $color-dark
  
.light-color
  color: $color-light

.accent-color
  color: $color-accent

.dark-background
  background: $color-dark
  
.light-background
  background: $color-light
  
.accent-background
  background: $color-accent

*
  box-sizing: border-box
  font-family: 'Montserrat', sans-serif

html,body
  height: 100%

#app
  min-height: 100%
  display: flex
  flex-direction: column

#app > header,main,footer
  padding: 0 10%

header
  height:  $spacer-size
  display: flex
  justify-content: space-between
  #logo
    width: $spacer-size

  img
    height: 100%
    padding: calc( $spacer-size / 10) 0


  .menu-open
    display: flex
    flex-direction: column
    position: absolute
    top: $spacer-size
    left: 0
    z-index: 2
    background: $color-dark
    width: 100vw
    animation: menu_anim 2s forwards
    transition: 1
    overflow-y: hidden

  nav
    display: flex
    flex: 1
    justify-content: flex-end
    @media screen and (max-width: 700px)
      display: none
    .nav-link
      display: block
      align-self: center
      color: $color-light
      text-decoration: none
      text-transform: uppercase
      padding: 1rem

      &:hover
        text-decoration: underline
    .nav-active
      color: $color-accent
  #nav-burger
    @media screen and (min-width: 700px)
      display: none
    display: flex
    flex-direction: column
    width: 32px
    height: 32px
    justify-content: space-between

    align-self: center
    
.line
    border-bottom: 2px solid $color-light 
    display: block    

.spacer
  height:  128px

main
  padding-top: 5%
  padding-bottom: 5%
  display: flex
  flex-direction: column
  flex: 1

html
  font-size: 14px
  @media screen and (min-width: 700px)
    font-size: 16px
  @media screen and (min-width: 1000px)
    font-size: 18px
  @media screen and (min-width: 1400px)
    font-size: 20px
  @media screen and (min-width: 1900px)
    font-size: 24px

.italic
  font-style: italic

.bold
  font-weight: bold

h3,h4,h5,h6,p
  padding: 0.3rem 0
h1
  font-size: 3rem
  padding: 0.1rem 0
h2
  font-size: 2rem
  padding: 0.5rem 0 
h3
  font-size: 1.5rem
h4
  font-size: 1.3rem
h5
  font-size: 1.2rem
h6
  font-size: 0.9rem
p
  font-size: 1rem

a
  color: inherit
  &:hover
    color: $color-accent 


.main-button
  width: 70%
  padding: 2rem
  font-size: 1.75rem
  margin: 3rem 0
  border: none
  @media screen and (max-width: 700px)
    width: 100%
</style>